'use strict';

var defaultConf = require('./config-default');
var BO_URL = 'micronora2022-data.ms-dev.mobile-spot.com';
var NODE_BACKEND_DOMAIN = 'micronora2022-node-backend-dev.mobile-spot.com';
var NODE_WS_BAKEND_DOMAIN = 'micronora2022-node-backend-ws-dev.mobile-spot.com';
module.exports = require('deepmerge')(defaultConf, {
  ENV: 'dev',
  BO_URL: BO_URL,
  // no trailing '/'
  BO_PATH: '/home/legacy/micronora/micronora2022/micronora2022-data.ms-dev.mobile-spot.com',
  // no trailing '/'
  BO_SERVER_ALIAS: 'ms-dev.mobilespot.prodleni.local',
  BO_SSH_USER: 'legacy',
  BO_SSH_PORT: '43122',
  //'43122'

  DEPLOY_PATH: '/home/legacy/micronora/micronora2022/micronora2022.ms-dev.mobile-spot.com',
  // TODO
  WEB_URL: 'https://www.micronora.com',
  GOOGLE_ANALYTICS_ID: 'UA-48506479-16',
  // GCM/FCM send id
  projectId: '759955685300',
  // pushwoosh appid
  appId: 'D1C69-BC386',
  SEND_EXPORTED_NOTES_MAIL: {
    WS_URL: 'http://localhost:3096/api/exportNotesByMail',
    EXPEDITOR: 'no-reply@mobile-spot.com',
    KEY: 'zpgZkgiz845gjzFzi'
  },
  ADDITIONAL_DEFAULTSRC_CSP: (Array.isArray(defaultConf.ADDITIONAL_DEFAULTSRC_CSP) ? defaultConf.ADDITIONAL_DEFAULTSRC_CSP : []).concat([]),
  CHECK_NETWORK_URL: "https://".concat(BO_URL, "/online.txt")
});