import { DATA_TYPE_EVENT_CATEGORIES, DATA_TYPE_EXHIBITOR_CATEGORIES, DATA_TYPE_SERVICES, DATA_TYPE_NEWPRODUCT_CATEGORIES, DATA_TYPE_ANIMATIONS, DATA_TYPE_SERVICE_CATEGORIES } from './dataConfig';
import { LIST_PAGE_KEY, MOBIGEO_PAGE_KEY, MEDIAS_PAGE_KEY } from 'src/pages/pagesKeys';
import AdSwap from 'src/components/ad-swap/AdSwap';
import HomeToolbar from 'src/pages/home/HomeToolbar';
import HomeTiles from 'src/pages/home/HomeTiles';
import MobilespotBar from 'src/components/mobilespot-bar/MobilespotBar';
import IphonexFlexMargin from 'src/components/iphonex-flex-margin/IphonexFlexMargin';
import { getUrl } from 'src/core/data-and-assets/DataAssetsUtil';
import { createItem, createItemRoute } from 'src/core/util/ConfigItemFactory';
import { openUrl } from 'src/core/util/JsTools';
import * as Query from 'src/core/query/Query';
import { AD_BUNDLE_ATTRIBUTION_KEYS } from 'src/core/config-json/ConfigJsonManager';
var LOG_PREF = '[homeConfig] ';
var _require = require('./profiles'),
  DEFAULT_PROFILE = _require.DEFAULT_PROFILE,
  WEBAPP_EXH_PROFILE = _require.WEBAPP_EXH_PROFILE,
  WEBAPP_EVT_PROFILE = _require.WEBAPP_EVT_PROFILE;

// NB: used for ChooseProfile page too !
export var getStyle = function getStyle() {
  return {
    background: "url(".concat(getUrl('files/project/home/home_background.jpg'), ") no-repeat"),
    backgroundSize: 'cover'
  };
};
export var BASE_COLUMN_COUNT = {
  landscape: 3,
  portrait: 2
};
export var MAX_COLUMN_SIZE = {
  landscape: 190,
  // 2 columns mode => less width available
  portrait: 250
};
export var GENERAL_TILE_OPTIONS = {
  height: 1,
  // rowspan
  width: 1 // colspan
};

var LABELS_COLOR = '#4C4C4C';
var createItemAd = function createItemAd(homebutton, lang, backgroundPosition) {
  return createItem(
  // Style
  {
    container: {
      style: {},
      className: 'home-btn-text'
    },
    icon: {
      style: {
        backgroundImage: "url(".concat(getUrl(homebutton["img_".concat(lang)]), ")")
      }
      // className: 'home-icon-plan',
    }
  },
  // Label (i18n)
  homebutton["label_".concat(lang)],
  // Action to perform on click
  function (actions) {
    var url = homebutton["link_".concat(lang)];
    var target = homebutton.target;
    if (openUrl(url, null, target, true)) {
      actions.linkClicked(url);
    }
  },
  // Tile options
  GENERAL_TILE_OPTIONS);
};
var getExhibitorsTile = function getExhibitorsTile() {
  return createItemRoute(
  // Style
  {
    container: {
      style: {
        color: LABELS_COLOR
      },
      className: 'centered-labels'
    },
    icon: {
      style: {
        backgroundImage: 'url(' + getUrl('files/project/home/exposants.svg') + ')'
      },
      className: 'home-icon-exposants'
    }
  },
  // Label (i18n)
  'home.exhibitors',
  // Page & props
  LIST_PAGE_KEY, {
    locateAll: false,
    inputs: [{
      dataType: DATA_TYPE_EXHIBITOR_CATEGORIES
    }]
  },
  // Tile options
  GENERAL_TILE_OPTIONS);
};
var getMobigeoTile = function getMobigeoTile() {
  return createItemRoute(
  // Style
  {
    container: {
      style: {
        color: LABELS_COLOR
      },
      className: 'centered-labels'
    },
    icon: {
      style: {
        backgroundImage: 'url(' + getUrl('files/project/home/plan.svg') + ')'
      },
      className: 'home-icon-plan'
    }
  },
  // Label (i18n)
  'home.map',
  // Page & props
  MOBIGEO_PAGE_KEY, null,
  // Tile options

  GENERAL_TILE_OPTIONS);
};
var getAnimationTile = function getAnimationTile() {
  return createItemRoute(
  // Style
  {
    container: {
      style: {
        color: LABELS_COLOR
      },
      className: 'centered-labels'
    },
    icon: {
      style: {
        backgroundImage: 'url(' + getUrl('files/project/home/animations.svg') + ')'
      },
      className: 'home-icon-animations'
    }
  },
  // Label (i18n)
  'home.programme', LIST_PAGE_KEY, {
    locateAll: true,
    inputs: [{
      dataType: DATA_TYPE_ANIMATIONS
    }]
  },
  // Page & props

  // Tile options
  GENERAL_TILE_OPTIONS);
};
var getEventsTile = function getEventsTile() {
  return createItemRoute(
  // Style
  {
    container: {
      style: {
        color: LABELS_COLOR
      },
      className: 'centered-labels'
    },
    icon: {
      style: {
        backgroundImage: 'url(' + getUrl('files/project/home/programme.svg') + ')'
      },
      className: 'home-icon-programme'
    }
  },
  // Label (i18n)
  'home.conferences',
  // Page & props
  LIST_PAGE_KEY, {
    locateAll: false,
    inputs: [{
      dataType: DATA_TYPE_EVENT_CATEGORIES
    }]
  },
  // Tile options
  GENERAL_TILE_OPTIONS);
};
var getMicronsTile = function getMicronsTile() {
  return createItemRoute(
  // Style
  {
    container: {
      style: {
        color: LABELS_COLOR
      },
      className: 'centered-labels'
    },
    icon: {
      style: {
        backgroundImage: 'url(' + getUrl('files/project/home/concours.svg') + ')'
      },
      className: 'home-icon-infos-pratique'
    }
  },
  // Label (i18n)
  'home.contests',
  // Page & props
  LIST_PAGE_KEY, {
    locateAll: false,
    inputs: [{
      dataType: DATA_TYPE_NEWPRODUCT_CATEGORIES
    }]
  },
  // Tile options
  GENERAL_TILE_OPTIONS);
};
var getServicesTile = function getServicesTile() {
  return createItemRoute(
  // Style
  {
    container: {
      style: {
        color: LABELS_COLOR
      },
      className: 'centered-labels'
    },
    icon: {
      style: {
        backgroundImage: 'url(' + getUrl('files/project/home/infos-pratiques.svg') + ')'
      },
      className: 'home-icon-infos-pratique'
    }
  },
  // Label (i18n)
  'home.practicalInfo',
  // Page & props
  LIST_PAGE_KEY, {
    locateAll: false,
    inputs: [{
      dataType: DATA_TYPE_SERVICE_CATEGORIES
    }]
  },
  // Tile options
  GENERAL_TILE_OPTIONS);
};
var getSocialMediaTile = function getSocialMediaTile() {
  return createItemRoute(
  // Style
  {
    container: {
      style: {
        color: LABELS_COLOR
      },
      className: 'centered-labels'
    },
    icon: {
      style: {
        backgroundImage: 'url(' + getUrl('files/project/home/Reseaux_Sociaux.svg') + ')'
      },
      className: 'home-icon-infos-pratique'
    }
  },
  // Label (i18n)
  'home.medias',
  // Page & props
  MEDIAS_PAGE_KEY, {
    controlMediaPageContent: {
      // hideTabMediaSocial: true,
      mediaTabKey: 'twitter'
    }
  },
  // Tile options
  GENERAL_TILE_OPTIONS);
};
var getDefault = function getDefault(lang, adConfig, orientation) {
  var hasAdButtons = adConfig && adConfig.homebuttons !== null && typeof adConfig.homebuttons === 'object';
  return [{
    component: AdSwap,
    props: {
      adBundleAttributionKey: AD_BUNDLE_ATTRIBUTION_KEYS.HOME_HEADER
    }
  }, {
    component: HomeToolbar
  }, {
    component: HomeTiles,
    props: {
      baseColumnCount: BASE_COLUMN_COUNT[orientation],
      maxColumnSize: MAX_COLUMN_SIZE[orientation],
      homeContent_Padding: 0,
      tiles: [
      // EXPOSANTS
      getExhibitorsTile(),
      // PLAN
      getMobigeoTile(),
      // ANIMATIONS
      getAnimationTile(),
      // PROGRAMME
      getEventsTile(),
      // MICRONS & NANO D’OR
      getMicronsTile(),
      // INFOS PRATIQUES
      getServicesTile(), hasAdButtons && adConfig.homebuttons.homebutton1 ? createItemAd(adConfig.homebuttons.homebutton1, lang) : null]
    }
  }, {
    component: AdSwap,
    props: {
      adBundleAttributionKey: AD_BUNDLE_ATTRIBUTION_KEYS.HOME
    }
  }, {
    component: MobilespotBar
  }, {
    component: IphonexFlexMargin
  }];
};
var getWebapp_exh_profile = function getWebapp_exh_profile(lang, adConfig, orientation, isLoggedIn) {
  var hasAdButtons = adConfig && adConfig.homebuttons !== null && typeof adConfig.homebuttons === 'object';
  return [{
    component: AdSwap,
    props: {
      adBundleAttributionKey: AD_BUNDLE_ATTRIBUTION_KEYS.HOME_HEADER
    }
  }, {
    component: HomeToolbar
  }, {
    component: HomeTiles,
    props: {
      baseColumnCount: BASE_COLUMN_COUNT[orientation],
      maxColumnSize: MAX_COLUMN_SIZE[orientation],
      homeContent_Padding: 0,
      tiles: [
      // EXPOSANTS
      getExhibitorsTile(),
      // PLAN
      getMobigeoTile(),
      // ANIMATIONS
      //getAnimationTile(),

      // PROGRAMME
      //getEventsTile(),

      // MICRONS & NANO D’OR
      //getMicronsTile(),

      // INFOS PRATIQUES
      //getServicesTile(),

      hasAdButtons && adConfig.homebuttons.homebutton1 ? createItemAd(adConfig.homebuttons.homebutton1, lang) : null]
    }
  }, {
    component: AdSwap,
    props: {
      adBundleAttributionKey: AD_BUNDLE_ATTRIBUTION_KEYS.HOME
    }
  }, {
    component: MobilespotBar
  }, {
    component: IphonexFlexMargin
  }];
};
var getWebapp_evt_profile = function getWebapp_evt_profile(lang, adConfig, orientation, isLoggedIn) {
  var hasAdButtons = adConfig && adConfig.homebuttons !== null && typeof adConfig.homebuttons === 'object';
  return [{
    component: AdSwap,
    props: {
      adBundleAttributionKey: AD_BUNDLE_ATTRIBUTION_KEYS.HOME_HEADER
    }
  }, {
    component: HomeToolbar
  }, {
    component: HomeTiles,
    props: {
      baseColumnCount: BASE_COLUMN_COUNT[orientation],
      maxColumnSize: MAX_COLUMN_SIZE[orientation],
      homeContent_Padding: 0,
      tiles: [
      // EXPOSANTS
      //getExhibitorsTile(),

      // PLAN
      getMobigeoTile(),
      // ANIMATIONS
      //getAnimationTile(),

      // PROGRAMME
      getEventsTile(),
      // MICRONS & NANO D’OR
      //getMicronsTile(),

      // INFOS PRATIQUES
      //getServicesTile(),

      hasAdButtons && adConfig.homebuttons.homebutton1 ? createItemAd(adConfig.homebuttons.homebutton1, lang) : null]
    }
  }, {
    component: AdSwap,
    props: {
      adBundleAttributionKey: AD_BUNDLE_ATTRIBUTION_KEYS.HOME
    }
  }, {
    component: MobilespotBar
  }, {
    component: IphonexFlexMargin
  }];
};

/**
 * Return home configuration
 * @param  {string} profile
 * @param  {string} lang
 * @param  {object} adConfig
 * @param  {string} orientation
 * @param  {boolean} isLoggedIn
 * @return {object}
 */
export function get(profile, lang, adConfig, orientation, isLoggedIn) {
  switch (profile) {
    // PRO
    case DEFAULT_PROFILE:
      return getDefault(lang, adConfig, orientation, isLoggedIn);
    // GRAND PUBLIC
    /*     case EXHIBITOR_PROFILE:
      return getExhibitor(lang, adConfig, orientation, isLoggedIn); */

    /*     case VISITOR_PROFILE:
      return getVisitor(lang, adConfig, orientation, isLoggedIn); */

    case WEBAPP_EVT_PROFILE:
      return getWebapp_evt_profile(lang, adConfig, orientation, isLoggedIn);
    case WEBAPP_EXH_PROFILE:
      return getWebapp_exh_profile(lang, adConfig, orientation, isLoggedIn);
    /* case WEBAPP_SPK_PROFILE:
      return getWebapp_spk_profile(lang, adConfig, orientation, isLoggedIn); */
    default:
      if (!profile) {
        console.warn("".concat(LOG_PREF, "No profile set yet"));
      } else {
        console.error("".concat(LOG_PREF, "Unsupported profile: ").concat(profile));
      }
  }
}