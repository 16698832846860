import React from 'react';
import PropTypes from 'prop-types';

import Loader from 'src/components/loader/Loader';
import DetailFixedTitle from 'src/components/detail-fixed-title/DetailFixedTitle';
import DetailPlaces from 'src/components/detail-places/DetailPlaces';
import Description from 'src/components/fiche/Description';
import TypeBar from 'src/components/type-bar/TypeBar';
import ShareButton from 'src/components/share-button/ShareButton';
import IphonexFlexMargin from 'src/components/iphonex-flex-margin/IphonexFlexMargin';
import NoteButton from 'src/components/notes/NoteButton';

import GenericIcon from 'src/components/generic-icon/GenericIcon';

import {
  DATA_TYPE_ANIMATIONS,
  DATA_TYPE_EVENTS,
  DATA_TYPE_EXHIBITORS,
  DATA_TYPE_SIDE_EVENTS,
} from 'app-customs/config/dataConfig';
import { EXPERIENCE_ITEM_PAGE_KEY, EXHIBITOR_PAGE_KEY, EVENT_PAGE_KEY, SIDE_EVENT_PAGE_KEY } from 'src/pages/pagesKeys';

import { getSortConfig } from 'app-customs/config/sortConfig';

import { getUrl } from 'src/core/data-and-assets/DataAssetsUtil';
import { sortStrings } from 'src/core/util/sortUtil';
import { formatDayLong } from 'src/core/Lang';

import './ExperienceItemPage.scss';

const closeDrawerThreshold = 10;
const sortEventFct = getSortConfig()?.[DATA_TYPE_EVENTS]?.sortFunction;
const sortSideEventFct = getSortConfig()?.[DATA_TYPE_SIDE_EVENTS]?.sortFunction;

class ExperienceItemContent extends React.PureComponent {
  setScrollableContentEl = (el) => {
    this.contentContainerEl = el;
  };
  scrollTo = (value) => {
    if (this.contentContainerEl) {
      this.contentContainerEl.scrollTop = value;
    }
  };

  renderItems = (items, dataType, pageKey) => {
    const { actions } = this.props;
    let sortedItems = items;

    if (dataType === DATA_TYPE_EXHIBITORS) {
      sortedItems = items.sort((itemA, itemB) => sortStrings(itemA.title, itemB.title));
    } else if (dataType === DATA_TYPE_EVENTS) {
      sortedItems = items.sort(sortEventFct);
    } else if (dataType === DATA_TYPE_SIDE_EVENTS) {
      sortedItems = items.sort(sortSideEventFct);
    }

    return (
      <div className="exp-data">
        {sortedItems.map((dataItem) => {
          const place =
            dataItem.place_id &&
            dataItem.references &&
            dataItem.references.places &&
            dataItem.references.places.length > 0
              ? dataItem.references.places.find((place) => place.id === dataItem.place_id)
              : null;

          const itemPlace =
            dataItem.place_id && dataItem.places && dataItem.places.length > 0
              ? dataItem.places.find((place) => place.place_id === dataItem.place_id)
              : null;

          /*
                    TODO
                    Extended fix in case of null data --> apply when sure
                    if(!place && !itemPlace)
                        return null
                    */
          return (
            <div key={dataItem.id} className="exp-data-item">
              {dataType === DATA_TYPE_EVENTS && (
                <span className="exp-data-date">
                  <div
                    className="start-date"
                    dangerouslySetInnerHTML={{ __html: formatDayLong(dataItem.start_date) }}
                  />
                  <div className="start-hour">{dataItem.start_time}</div>
                  {dataItem.start_date !== dataItem.end_date && (
                    <div
                      className="end-date"
                      dangerouslySetInnerHTML={{ __html: formatDayLong(dataItem.end_date) }}
                    />
                  )}
                  <div className="end-hour">{dataItem.end_time}</div>
                </span>
              )}

              <div className="exp-data-description">
                <span
                  className={dataType === DATA_TYPE_EVENTS ? 'exp-data-title-sm' : 'exp-data-title'}
                >
                  {dataItem.title}
                </span>
                <span className="exp-data-details">
                  {place && <span className="exp-data-location">{place.label}</span>}
                  {itemPlace && itemPlace.info && (
                    <span className="exp-data-info">{itemPlace.info}</span>
                  )}
                </span>
              </div>

              <div className="exp-data-links">
                {place && (
                  <GenericIcon
                    style={{ fontSize: '1.2em' }}
                    onClick={() =>
                      actions.showOnePoiOnMobigeo({
                        id: dataItem.id,
                        originalId: dataItem.original_id,
                        type: dataType,
                        placeId: place.id,
                      })
                    }
                    className="exp-data-link map-row"
                  />
                )}

                <GenericIcon
                  className="exp-data-link file-icon"
                  style={{ fontSize: '1.2em' }}
                  onClick={() => actions.navigate(pageKey, { id: dataItem.id })}
                />
              </div>
            </div>
          );
        })}
      </div>
    );
  };

  render() {
    let animation = this.props.item;
    console.log('this.props.item', this.props.item);

    if (!animation) {
      return <Loader labels={this.props.labels} />;
    }

    const references = animation.references,
      title = animation.title,
      logoFileName = animation.logo_file_name,
      imageUrl = logoFileName ? getUrl(animation.logo_file_name) : null,
      events = references.events && references.events.length > 0 ? references.events : null,
      sideevents =
        references.sideevents && references.sideevents.length > 0 ? references.sideevents : null,
      exhibitors =
        references.exhibitors && references.exhibitors.length > 0 ? references.exhibitors : null;
    /*
        TODO
        Extended fix ( data filtering ) in case of null data --> apply when sure
        function filtrerNulls(obj) {
          if (!obj.references) {
            return false;
          } else {
            return true;
          }
        }

        const
            references = animation.references,
            title = animation.title,
            logoFileName = animation.logo_file_name,
            imageUrl = logoFileName ? getUrl(animation.logo_file_name) : null,
            events = references.events && references.events.length > 0 ? references.events.filter(filtrerNulls) : null,
            exhibitors = references.exhibitors && references.exhibitors.length > 0 ? references.exhibitors.filter(filtrerNulls) : null;
        */

    const handleClickOnContent = (e) => {
      let dataset = e.target.dataset;
      if (dataset.link) {
        this.props.actions.applyRedirect(
          JSON.parse(decodeURIComponent(dataset.link)),
          this.props.actions
        );
      }
    };

    return (
      <div className="fiche content-font experience content-below-apptoolbar">
        <DetailFixedTitle
          title={title}
          dataType={DATA_TYPE_ANIMATIONS}
          isFav={this.props.isFavorite}
          onFavClick={() =>
            this.props.actions.toggleFavorite(
              animation.id,
              DATA_TYPE_ANIMATIONS,
              this.props.isFavorite
            )
          }
          labels={this.props.labels}
        >
          <NoteButton
            itemTitle={this.props.item.title}
            itemId={this.props.item.id}
            dataType={DATA_TYPE_ANIMATIONS}
            hasNote={this.props.hasNote}
            labels={this.props.labels}
            actions={this.props.actions}
          />
          <ShareButton
            name={title}
            queryString={this.props.queryString}
            description={animation.description}
            image={imageUrl}
            labels={this.props.labels}
            actions={this.props.actions}
            profile={this.props.profile}
            pageKey={EXPERIENCE_ITEM_PAGE_KEY}
          />
        </DetailFixedTitle>

        <div className="experience-item-wrapper" ref={this.setScrollableContentEl}>
          {imageUrl && (
            <div
              className="experience-img-container"
              style={{
                backgroundImage: `url(${imageUrl})`,
              }}
            ></div>
          )}

          <DetailPlaces
            actions={this.props.actions}
            id={animation.id}
            originalId={animation.original_id}
            places={animation.references.places}
            labels={this.props.labels}
            entityType={DATA_TYPE_ANIMATIONS}
          />

          <Description
            labels={this.props.labels}
            dataType={DATA_TYPE_ANIMATIONS}
            onClick={handleClickOnContent}
            description={animation.description}
            preview
          />

          {exhibitors && (
            <TypeBar
              key={DATA_TYPE_EXHIBITORS}
              label={
                this.props.labels.data[DATA_TYPE_EXHIBITORS][
                  exhibitors.length > 1 ? 'plural' : 'singular'
                ]
              }
              count={exhibitors.length}
              isClosed={exhibitors.length > closeDrawerThreshold}
            >
              {this.renderItems(exhibitors, DATA_TYPE_EXHIBITORS, EXHIBITOR_PAGE_KEY)}
            </TypeBar>
          )}

          {events && (
            <TypeBar
              key={DATA_TYPE_EVENTS}
              label={
                this.props.labels.data['conferences'][events.length > 1 ? 'plural' : 'singular']
              }
              count={events.length}
              isClosed={events.length > closeDrawerThreshold}
            >
              {this.renderItems(events, DATA_TYPE_EVENTS, EVENT_PAGE_KEY)}
            </TypeBar>
          )}

          {sideevents && (
            <TypeBar
              key={DATA_TYPE_SIDE_EVENTS}
              label={
                this.props.labels.data['conferences'][sideevents.length > 1 ? 'plural' : 'singular']
              }
              count={sideevents.length}
              isClosed={sideevents.length > closeDrawerThreshold}
            >
              {this.renderItems(sideevents, DATA_TYPE_SIDE_EVENTS, SIDE_EVENT_PAGE_KEY)}
            </TypeBar>
          )}
        </div>

        <IphonexFlexMargin />
      </div>
    );
  }
}

ExperienceItemContent.propTypes = {
  item: PropTypes.object,
  isFavorite: PropTypes.bool,
  queryString: PropTypes.string.isRequired,
  actions: PropTypes.object.isRequired,
  labels: PropTypes.object.isRequired,
};

export default ExperienceItemContent;
